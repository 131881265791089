/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

/* This is to override some of the styling from mosaic-window (@blueprintjs under the hood), remove this when we have custom styling for workspace */
:focus {
  outline: none;
}

.mosaic-window .mosaic-window-toolbar,
.mosaic-preview .mosaic-window-toolbar {
  height: 24px;
}
.mosaic-root {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.mosaic-tile {
  margin: 1.5px;
}
