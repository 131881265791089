/* *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 *
 * Copyright 2022 - Koninklijk Nederlands Meteorologisch Instituut (KNMI)
 * Copyright 2022 - Finnish Meteorological Institute (FMI)
 * Copyright 2024 - The Norwegian Meteorological Institute (MET Norway)
 * */

.snowflake {
  color: rgb(207, 220, 249);
  width: 10px;
  height: 10px;
  opacity: 50%;
  position: fixed;
  pointer-events: none;
  z-index: 9001;
  animation:
    snow-fall var(--fall-duration) linear var(--fall-delay) infinite forwards,
    snow-sway 5s ease-in-out var(--sway-delay) infinite alternate;
}

@keyframes snow-sway {
  to {
    transform: translateX(20px);
  }
}

@keyframes snow-fall {
  from {
    top: -2vw;
  }
  to {
    top: 100%;
  }
}
